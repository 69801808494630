import React, { useCallback, useEffect, useState } from 'react';
import {
  List,
  DateField,
  ArrayField,
  SingleFieldList,
  usePermissions,
  ReferenceArrayField,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useGetList,
  Identifier,
  useListFilterContext,
  useStore,
  DatagridConfigurable,
  SelectColumnsButton,
  FilterButton,
  TopToolbar,
  SelectField,
  SelectInput,
} from 'react-admin';

import {
  Box,
  Card, CircularProgress, Divider, Typography,
} from '@mui/material';

import { capitalize } from 'lodash';
import ReferenceEntityField from '../../customFields/ReferenceEntityField';
import StatusField from '../../customFields/StatusField';
import TeamChip from '../../customFields/ColoredChipField';

import { SubListNavigation } from '../layout/SubNavigation';
import { StyledTab, StyledTabs } from '../layout/Tab';
import Pagination from '../layout/Pagination';
import NoResults from '../layout/NoResults';
import LinkField from '../layout/LinkField';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';

import hasAccess from '../../utilities/hasAccess';

import MergeCases from './bulkActions/MergeCases';

import EntityFilter from '../filters/EntityFilter';
import RuleFilter from '../filters/RuleFilter';
import CaseAssigneeFilter from '../filters/CaseAssigneeFilter';
import { Actions } from '../../constants/actions';
import caseStatuses from '../../constants/caseStatuses';
import CaseAssign from './components/CaseAssign';
import casePriority from '../../constants/casePriority';
import CaseEscalationFilter from '../filters/CaseEscalationFilter';
import CaseEscalate from './components/CaseEscalate';

export const CaseListBody = ({
  bulkActionButtons = true,
}: {
  bulkActionButtons?: boolean
}) => {
  const { permissions } = usePermissions<Actions[]>();

  return (
    <DatagridConfigurable
      bulkActionButtons={(hasAccess(permissions, 'CaseUpdate') && bulkActionButtons) ? <MergeCases /> : false}
      sx={boldDataGridStyle}
      empty={<NoResults variant="h6" />}
    >
      <LinkField sortable={false} source="id" />
      <DateField sortable source="createdAt" showTime />
      <ArrayField sortable={false} source="entities" label="Entities">
        <SingleFieldList linkType={false}>
          <ReferenceEntityField source="" chip />
        </SingleFieldList>
      </ArrayField>
      <ReferenceArrayField sortable={false} source="tags" reference="tags" label="Tags" emptyText="-">
        <SingleFieldList placeholder="-">
          <TeamChip />
        </SingleFieldList>
      </ReferenceArrayField>
      <StatusField sortable={false} source="status" />
      <CaseAssign label="Assignee" />
      <CaseEscalate label="Escalated To" />
      <SelectField
        source="priority"
        sortable={false}
        choices={
          Object.values(casePriority)
            .map((priority) => ({
              name: capitalize(priority),
              id: priority,
            }))
        }
      />
    </DatagridConfigurable>
  );
};

const CaseCountLabel = ({
  count,
  label,
  countIsLoading,
  shouldCount,
}: {
  count?: number
  label: string
  countIsLoading: boolean
  shouldCount: boolean
}) => {
  if (!shouldCount) return <Typography>{label}</Typography>;

  if (countIsLoading) {
    return (
      <Box display="flex" alignItems="center" gap={2}>
        <Typography>{label}</Typography>
        <CircularProgress size={11} />
      </Box>
    );
  }

  return <Typography>{`${label} (${count ?? 0})`}</Typography>;
};

const CaseCountProvider = ({
  setCount,
  setCountIsLoading,
  shouldCount,
  setShouldCount,
  children,
}: {
  setCountIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  shouldCount: boolean
  setShouldCount: React.Dispatch<React.SetStateAction<boolean>>
  setCount: React.Dispatch<React.SetStateAction<{
    id: Identifier;
    open: number,
    closed: number
    'in-progress': number
    pending: number
  } | undefined>>
  children: React.ReactElement
}) => {
  const { filterValues } = useListFilterContext();

  useEffect(
    () => {
      setShouldCount(Object.keys(filterValues).every((key) => key === 'status'));
    },
    [filterValues, setShouldCount],
  );

  const { data, isLoading } = useGetList<{
    id: Identifier,
    open: number,
    closed: number
    'in-progress': number
    pending: number
  }>('cases/count', { filter: filterValues }, { enabled: shouldCount });

  useEffect(() => {
    setCountIsLoading(isLoading);
  }, [isLoading, setCountIsLoading]);

  useEffect(() => {
    setCount(data?.at(0));
  }, [data, setCount]);

  return children;
};

const ListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
  </TopToolbar>
);

const CaseList = () => {
  const [statusTab, setStatusTab] = useStore('case.statusTab', 0);
  const [status, setStatusFilter] = useStore('case.status', 'Open');

  const [countIsLoading, setCountIsLoading] = useState(false);
  const [shouldCount, setShouldCount] = useState(true);
  const [count, setCount] = useState<{
    id: Identifier,
    open: number,
    closed: number
    'in-progress': number
    pending: number
  }>();

  const handleStatusChange = useCallback((
    _event: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    setStatusTab(newValue);
    if (newValue === 0) setStatusFilter(caseStatuses.OPEN);
    if (newValue === 1) setStatusFilter(caseStatuses.IN_PROGRESS);
    if (newValue === 2) setStatusFilter(caseStatuses.PENDING);
    if (newValue === 3) setStatusFilter(caseStatuses.CLOSED);
  }, [setStatusFilter, setStatusTab]);

  return (
    <List
      filter={{ status }}
      storeKey="case.list"
      empty={false}
      pagination={<Pagination disableEmptyText />}
      filters={[
        <ReferenceArrayInput source="tags" reference="tags" filter={{ active: true }}>
          <AutocompleteArrayInput
            label="Tag"
            filterToQuery={(searchText: string) => ({ name: searchText })}
            optionText="name"
          />
        </ReferenceArrayInput>,
        <EntityFilter label="Entities" source="entities" />,
        <RuleFilter source="pings.rule" label="Rules" />,
        <CaseAssigneeFilter source="assignee" />,
        <CaseEscalationFilter source="escalatedTo" />,
        <SelectInput
          fullWidth
          source="priority"
          label="Priority"
          choices={
            Object.values(casePriority)
              .map((item) => ({
                name: capitalize(item),
                id: item,
              }))
          }
        />,
      ]}
      sort={{ field: 'createdAt', order: 'DESC' }}
      exporter={false}
      actions={<ListActions />}
    >
      <Card sx={{ margin: 0 }} variant="outlined">
        <CaseCountProvider
          shouldCount={shouldCount}
          setCount={setCount}
          setShouldCount={setShouldCount}
          setCountIsLoading={setCountIsLoading}
        >
          <Card sx={{ margin: 0 }}>
            <SubListNavigation>
              <StyledTabs value={statusTab} onChange={handleStatusChange}>
                <StyledTab
                  label={React.createElement(CaseCountLabel, {
                    count: count?.open,
                    label: 'Open',
                    countIsLoading,
                    shouldCount,
                  })}
                />
                <StyledTab
                  label={React.createElement(CaseCountLabel, {
                    count: count?.['in-progress'],
                    label: 'In Progress',
                    countIsLoading,
                    shouldCount,
                  })}
                />
                <StyledTab
                  label={React.createElement(CaseCountLabel, {
                    count: count?.pending,
                    label: 'Pending',
                    countIsLoading,
                    shouldCount,
                  })}
                />
                <StyledTab
                  label={React.createElement(CaseCountLabel, {
                    count: count?.closed,
                    label: 'Closed',
                    countIsLoading,
                    shouldCount,
                  })}
                />
              </StyledTabs>
            </SubListNavigation>
            <Divider />
          </Card>
        </CaseCountProvider>
        <CaseListBody />
      </Card>
    </List>
  );
};

export default CaseList;
